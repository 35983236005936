<template>
	<div>
		<el-form  v-loading="loading" :model="formData" status-icon label-width="100px" :rules="rules" ref="ruleForm" >
			<el-form-item label="角色名称" prop="pid">
				<el-cascader ref="cascader" filterable placeholder="顶级角色" popper-class='cascaders' v-model="formData.pid" :options="data" clearable :props="{label:'name',value:'id',checkStrictly:true, expandTrigger: 'hover',emitPath:false}">
					<template slot-scope="{ node, data }">
						<div @click="cascaderClick(data)">
							<span>{{ data.name }}</span>
							<span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
						</div>
					</template>
				</el-cascader>
			</el-form-item>
			<el-form-item label="角色名称" prop="name">
				<el-input type="text" v-model="formData.name"></el-input>
			</el-form-item>
		</el-form>
		<div class="drawer-footer">
			<Button style="margin-right: 8px">重置</Button>
			<Button type="primary" @click="onSubmit('ruleForm')">提交</Button>
		</div>
	</div>
</template>

<script>
	import {request} from "@/library/request.js";
	export default {
		data(){
			return{
				value:"",
				loading:true,
				id:"",
				data:null,
				formData:{
					pid:-1,
					name:""
				},
				rules:{
					name:[
						{required: true, message: '请输入角色名称', trigger: 'blur' }
					],
				}
			}
		},
		methods:{
		
			onSubmit(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					} else {
						return false;
					}
				});
			},
			save(){

				this.loading = true
				var that = this
				return request({
					url:'system/role/edit/'+this.id,
					method:"post",
					params:this.formData
				}).then(response  => {
					this.loading = false
					if(response.code==200){
						this.$Message.success({
							content:response.msg,
							duration:1.5,
							onClose:function(){	
								that.$emit('routerCancel', true)
							}
						})
					}else{
						this.$Message.error({
							content:response.msg,
							duration: 3
						})
					}
				}).then(err => {
					if(err){
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			details(id){
				request({
					url:'system/role/edit/'+id,
					method:"get",
				}).then(response  => {
					
					if(response.code==200){
						this.formData.name = response.data.name
					}else{
						this.loading = false
						this.$Message.error({
							content:response.msg,
							duration: 3
						})
					}
				}).then(err => {
					if(err){
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				var search = {
					selectFields:["id","name","pid"]
				}
				return request({
					url:'system/role/list',
					method:"get",
					params:search
				}).then(response  => {
					this.loading = false
					this.$Loading.finish();
					this.loading = false
					this.data = response.data
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			cascaderClick (nodeData) {
				this.$refs.cascader.checkedValue = nodeData.id;
				this.$refs.cascader.computePresentText();
				this.$refs.cascader.toggleDropDownVisible(false);
    		},
		},
		watch:{
			'$route.params': {
				handler(newVal,oldVal){
					this.id = newVal.id
					this.details(newVal.id)
					this.formData.pid=newVal.pid
				},
				immediate: true
			},
		}
	}
</script>
<style scoped>
	.drawer-footer{
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
</style>
<style>
	.cascaders .el-radio__inner {
		top: -18px;
		left: -19px;
		border: 0;
		height: 34px;
		background-color: transparent;
		position: absolute;
	}
	.cascaders .el-radio__input.is-checked .el-radio__inner {
		background: transparent;
	}
</style>
